<!--业扩流程指引-->
<template>
    <widget-framework v-if="dataLoaded" title="业扩流程指引">
        <template #content>
            <div class="container">
                <el-timeline>
                    <el-timeline-item
                        v-for="(item, index) in data"
                        :key="index"
                        :hideTimestamp="true"
                    >
                        <div slot="dot" class="dotOuter">
                            <div class="dotInner">
                                {{index + 1}}
                            </div>
                        </div>
                        <div class="content">
                            {{item.content}}
                        </div>
                        <div class="next">
                            <i class="el-icon-arrow-right" />
                            <i class="el-icon-arrow-right" />
                        </div>
                    </el-timeline-item>
                </el-timeline>
            </div>
        </template>
    </widget-framework>
    <dv-loading v-else></dv-loading>
</template>

<script>
import WidgetFramework from '@/components/widgets/WidgetFramework';

export default {
    name: 'BusinessApplyProcessGuide',
    components: {
        WidgetFramework
    },
    data() {
        return {
            dataLoaded: false,
            data: [{
                content: '发起申请',
            }, {
                content: '现场勘查',
            }, {
                content: '供电方案',
            }, {
                content: '工程验收',
            }]

        };
    },
    mounted() {
        setTimeout(() => {
            this.dataLoaded = true;
        }, 1000);
    }
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';

@contentWidth: 80px;

.container {
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-left: 15px;
    align-items: flex-start;
    justify-content: center;
}

/deep/ .el-timeline{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .el-timeline-item{
        display: inline-flex;
        width: 25%;

        &:last-child {
            width: calc(25% - @contentWidth / 2);
        }

        .el-timeline-item__tail {
            left: 20px;
            height: unset;
            width: calc(100% - 30px);
            border-left: unset;
            border-top: 2px dotted @cyan;
            top: 10px;
        }

        .el-timeline-item__wrapper {
            width: 100%;
            top: 40px;
            left: -@contentWidth / 2;
            padding-left: unset;

            .el-timeline-item__content {
                display: flex;
            }
        }
    }
}

.dotOuter {
    position: absolute;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: @cyan 1px solid;
    top: 0px;
    left: -8px;
    width: 24px;
    height: 24px;

    .dotInner {
        border: @cyan 1px solid;
        background-color: rgba(0, 204, 255, 0.6);
        width: 18px;
        height: 18px;
        border-radius: 50%;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

    }
}

.content {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: @cyan;
    background-color: rgba(0, 250, 247, 0.1);
    width: @contentWidth;
    height: 40px;
    font-size: 12px;
}

.next {
    display: inline-flex;
    color: @cyan;
    flex-grow: 1;
    align-items: center;
    justify-content: center;

    .el-timeline-item:last-child & {
        display: none;
    }

    .el-icon-arrow-right {
        width: 5px;
        margin-left: -10px;
    }
}

</style>
